<template>
    <div class="content">
        <div class="header">
            <div class="title">{{ $t('meeting_session.final_recap') }}</div>
            <slot name="header_menu_right"></slot>
        </div>
        <div class="body meeting-summary" v-if="loaded">
            <div class="container-form">

                <!-- <div class="sub-title">{{ $t('add_meeting.participants') }}</div>
                <div class="participants-list">
                    <div class="participant" v-for="user in participants">
                        <div class="avatar">
                            <img :src="user.avatar"/>
                        </div>
                        <div class="data">
                            <div class="name">{{ user.name }}</div>
                            <div class="email">{{ user.email }}</div>
                        </div>
                        <div class="response" :class="[user.response]">
                            <template v-if="user.response == 'yes'">{{ $t('meeting_session.presence_status.yes')}}</template>
                            <template v-else-if="user.response == 'no'">{{ $t('meeting_session.presence_status.no')}}</template>
                            <template v-else-if="user.response == 'holiday'">{{ $t('meeting_session.presence_status.holiday')}}</template>
                            <template v-else-if="user.response == 'maybe'">{{ $t('meeting_session.presence_status.maybe')}}</template>
                            <template v-else>{{ $t('meeting_session.presence_status.unconfirmed')}}</template>
                        </div>
                    </div>
                </div> -->
                
                <div class="sub-title">{{ $t('meeting_session.summary_description') }}</div>
                <div class="summary-data mrg-bot" v-if="summary">
                    <div class="steps" v-for="step in summary">
                        <div class="title" v-html="step.name"></div>

                        <ul v-if="step.summaries && step.summaries.length">
                            <li class="item-list" v-for="summaryItem in step.summaries" v-html="summaryItem"></li>
                        </ul>

                        <template v-if="step.type == 'key_result'">
                            <div class="sub-list" v-for="keyResult in step.key_results">
                                <div class="name" v-html="`${keyResult.name}`"></div>
                                <ul>
                                    <li class="item-list" v-for="summaryItem in keyResult.summaries" v-html="summaryItem"></li>
                                </ul>
                            </div>
                        </template>

                        <template v-if="step.type == 'today_planning'">
                            <div class="sub-list" v-for="user in step.users">
                                <div class="name" v-html="`${user.name}`"></div>
                                <ul>
                                    <li class="item-list" v-for="summaryItem in user.summaries" v-html="summaryItem"></li>
                                </ul>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-else class="sub-title light">
                    {{ $t('meeting_session.no_action_summary')}}
                </div>

                <!-- <div class="sub-title">Aici poti sa mai adaugi mentiuni la rezultatul intalnirii:</div>
                <div class="answer-textarea">
                    <textarea placeholder="Mentiuni la rezumatul intalnirii" rows="10" v-model="mentions"></textarea>
                </div> -->
                <slot name="form_submit"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import IconTask from '@/components/Icons/Task'
import IconCheck from '@/components/Icons/Check'
import IconFlag from '@/components/Icons/Flag'

export default {
    components: {
        IconTask,
        IconCheck,
        IconFlag
    },
    computed: {

    },
    props: {
        optionsUsers: Array,
        data: Object|Boolean
    },
    data() {
        return {
            loaded: false,
            mentions: '',
            summary: {},
            topic: [],
            participants: [],
            meeting: {}
        }
    },
    async mounted() {
        this.getSummary();
    },
    methods: {
        getSummary(){
            axios.get(`/public/meeting-session/${this.$route.params.uuid}/summary`)
            .then(({data}) => {
                const { summary, topic, participants, name, created_at, description, duration, link, location, rating } = data.data;

                this.summary = summary;
                this.participants = participants;
                this.topic = topic;
                this.meeting = {
                    name,
                    created_at,
                    description,
                    duration,
                    link,
                    location,
                    rating
                };
            })
            .finally(() => {
                this.loaded = true;
            })
        }
    }
}
</script>